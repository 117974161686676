import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Episode, { Kind } from '../components/Episode';

import Layout from '../components/Layout';
import SEO from '../components/seo';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />

    {data.allMdx.edges.map((item, idx) => (
      <Episode
        key={item.node.id}
        kind={idx ? Kind.Brief : Kind.Full}
        {...item.node}
        {...item.node.frontmatter}
        {...item.node.fields}
      />
    ))}
  </Layout>
);
IndexPage.propTypes = {
  data: PropTypes.object,
};
IndexPage.defaultProps = {
  data: {},
};

export const pageQuery = graphql`
  {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { slug: { regex: "/^.(?!sponsors)/" }}, isFuture: { eq: false } }
    ) {
      edges {
        node {
          id
          slug
          body
          frontmatter {
            title
            website
            pronoun
            url
            episodeNumber
            episodeArt
            date(fromNow: true)
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
